<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Admin</li>
          <li class="breadcrumb-item active">Edit user</li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{ name: 'user-management' }"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>Edit user</h1>
          </div>
        </div>

        <div v-if="newUserInfo.id">
          <div class="row row-has-panel">
            <div class="col-lg-12">
              <card title="Profile Details">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="col-md-6 col-lg-4">
                      <div
                        class="form-group"
                        :class="{ 'form-error': !newUserInfo.name }"
                      >
                        <label>Name</label>
                        <input
                          v-model="newUserInfo.name"
                          type="text"
                          test-id="name"
                          class="form-control"
                          required
                        />
                        <div v-if="!newUserInfo.name" class="form-error-message">
                          The field is empty
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div
                        class="form-group"
                        :class="{ 'form-error': !newUserInfo.last_name }"
                      >
                        <label>Last name</label>
                        <input
                          v-model="newUserInfo.last_name"
                          type="text"
                          test-id="lastName"
                          class="form-control"
                          required
                        />
                        <div v-if="!newUserInfo.last_name" class="form-error-message">
                          The field is empty
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Phone number</label>
                        <input
                          v-model="newUserInfo.phone"
                          type="text"
                          test-id="phone"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </card>
            </div>
          </div>
          <div class="row row-has-panel">
            <div class="col-lg-12">
              <card title="Expert" :description="timezone" icon="/img/icons/icon-clock.svg">
                <form class="form-horizontal">
                  <div class="row">
                    <div class="custom-checkbox-block col-md-6 col-lg-4">
                      <div class="custom-checkbox-slide">
                        <input
                          id="enable-be-expert"
                          v-model="newUserInfo.is_remote_expert"
                          type="checkbox"
                        />
                        <label for="enable-be-expert">
                          Give the user ‘Expert’ status
                        </label>
                      </div>
                    </div>
                    <div class="custom-checkbox-block col-md-6 col-lg-4">
                      <div class="custom-checkbox-slide" :class="{'disabled' : !newUserInfo.is_remote_expert}">
                        <input
                          id="enable-contact-phonebook"
                          v-model="newUserInfo.is_phonebook"
                          type="checkbox"
                          :disabled="!newUserInfo.is_remote_expert"
                        />
                        <label for="enable-contact-phonebook">
                          Show contact in Phonebook
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="newUserInfo.is_remote_expert && newUserInfo.workingTimes" class="form-group">
                    <div class="row">
                      <div class="col-md-6 col-lg-4">
                        <div class="mt-2">
                          <label>Working Days</label>
                        </div>
                      </div>
                      <div class="working-hours col-md-6 col-lg-4">
                        <div class="mt-2 md-6">
                          <label>Working Hours</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="newUserInfo.workingTimes">
                      <div v-for="day in newUserInfo.workingTimes" :key="day.id" class="row">
                        <div class="working-day-block col-md-6 col-lg-4">
                          <div class="custom-checkbox mt-2">
                            <input
                              :id="day.id"
                              v-model="day.is_active"
                              type="checkbox"
                            />
                            <label :for="day.id"> {{ day.day }} </label>
                          </div>
                        </div>
                        <div class="form-group-note col-md-6 col-lg-4" :class="{ 'form-error': day.is_active && !day.from }">
                          <label class="label-day" :class="{ 'disabled-day': !day.from }" for="from_time">From:</label>
                          <div class="container">
                            <input
                              id="from_time"
                              v-model="day.from"
                              type="time"
                              class="form-control time_input"
                              aria-label="from-time"
                              aria-describedby="basic-addon1"
                              :disabled="!day.is_active"
                              :required="day.is_active"
                            />
                            <div v-if="day.is_active && !day.from" class="form-error-message">
                              The field is empty
                            </div>
                          </div>
                        </div>
                        <div class="form-group-note col-md-6 col-lg-4" :class="{ 'form-error': day.is_active && !day.to }">
                          <label class="label-day" :class="{'disabled-day': !day.is_active}" for="to_time">To:</label>
                          <div class="container">
                            <input
                              id="to_time"
                              v-model="day.to"
                              type="time"
                              class="form-control time_input"
                              aria-label="to-time"
                              aria-describedby="basic-addon1"
                              :disabled="!day.is_active"
                              :required="day.is_active"
                            />
                            <div v-if="day.is_active && !day.to" class="form-error-message">
                              The field is empty
                            </div>
                            <div v-else-if="day.is_active && day.to < day.from" class="form-error-message">
                              The "From" date is more than the "To" date
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </card>
            </div>
          </div>
          <div class="row row-has-panel">
            <div class="col-lg-12">
              <div v-if="userDetailsChanged" class="ml-auto">
                <div class="form-group text-md-right m-0">
                  <button
                    type="submit"
                    class="btn btn-md btn-primary"
                    :disabled="!isDetailsValid"
                    @click.prevent="updateUserDetails"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {userStore} from '@/store/__STORE_user';

import {isEqual, cloneDeep} from 'lodash';

import {Card} from '@/components/Styling';

export default {
  name: 'EditUser',
  components: {
    Card,
  },
  data() {
    return {
      newUserInfo: {},
    };
  },
  computed: {
    userInfo() {
      return userStore.__GETTERuserFields;
    },
    userDetailsChanged() {
      return !isEqual(this.userInfo, this.newUserInfo);
    },
    isDetailsValid() {
      return this.newUserInfo.name &&
        this.newUserInfo.last_name &&
        (this.newUserInfo ?
          this.newUserInfo?.workingTimes?.every((day) =>
            day.is_active ?
                Boolean(day.to?.length === 5 && day.from?.length === 5 && day.from < day.to):
                true,
          ) :
          true);
    },
    timezone() {
      const gtm = this.userInfo?.timezone_offset ? ' GMT' + this.userInfo?.timezone_offset : '';
      return this.userInfo?.timezone ? this.userInfo?.timezone + gtm : '';
    },
  },
  watch: {
    'newUserInfo.is_remote_expert'(val) {
      if (typeof val === 'boolean') {
        this.newUserInfo.is_phonebook = val;
        if (!val) {
          this.newUserInfo.workingTimes.forEach((day) => {
            day.is_active = false;
            day.to = null;
            day.from = null;
          });
        }
      }
    },
  },
  mounted() {
    userStore.getUserFields(this.$route.params.id).then((response) => {
      this.newUserInfo = cloneDeep(response.data);
    });
  },
  destroyed() {
    userStore.changeUserFields({});
  },
  methods: {
    updateUserDetails() {
      if (this.isDetailsValid) {
        userStore.setUserFields(cloneDeep(this.newUserInfo))
            .then(() => {
              this.$toast.success('Updated successfully!');
            })
            .catch((err) => {
              const data = err.response.data;
              this.$toast.error(data.message);
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.disabled-day {
    opacity: 0.5;
}

.working-day-block {
    @media (max-width: $breakpoint-lg) {
        min-width: 100%;
    }
}

.working-hours {
    @media (max-width: $breakpoint-lg) {
        display: none;
    }
}

.custom-checkbox-block:last-child {
    @media (max-width: $breakpoint-md) {
        margin-top: 20px;
    }
}

.label-day {
    @media (max-width: $breakpoint-md) {
        min-width: 40px;
    }
}
</style>
